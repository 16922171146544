@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-toastify/dist/ReactToastify.css';
@import "aos/dist/aos.css";
@import "@pathofdev/react-tag-input/build/index.css";
@import 'react-chat-elements/dist/main.css';
@import "react-datepicker/dist/react-datepicker.css";
@import 'react-phone-number-input/style.css';

.react-datepicker-wrapper {
	display: inline-block;
	padding: 0;
	border: 0;
	width: 100% !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar {
	overflow-y: scroll;
	/* Enable vertical scrolling */
	scrollbar-width: thin;
	/* For Firefox */
	scrollbar-color: transparent transparent;
	/* For Firefox */
}

/* Hide the PayPal option */
.paypal-button[data-funding-source="paypal"] {
	display: none !important;
  }
  
  /* Hide the Pay Later option (if exists) */
  .paypal-button[data-funding-source="paylater"] {
	display: none !important;
  }
  
  /* Hide the button container completely if you want to remove it from the DOM */
  .paypal-button-row {
	display: none !important;
  }
  
  /* Show only the credit card button */
  .paypal-button[data-funding-source="credit"] {
	display: block !important;
  }
  

/* styles.css or your main CSS file */
.transition-max-height {
	overflow: hidden;
}

.fade-out {
	opacity: 0;
	transition: opacity 0.5s ease-out;
}

/* Webkit browsers */
.hide-scrollbar::-webkit-scrollbar {
	width: 0;
	/* Width of the scrollbar */
	height: 0;
	/* Height of the scrollbar */
}

/* You can also style the content inside the scrollable area */
.hide-scrollbar::-webkit-scrollbar-thumb {
	background: transparent;
	/* Color of the scroll thumb */
}

/* Optional: to style the scrollbar track */
.hide-scrollbar::-webkit-scrollbar-track {
	background: transparent;
	/* Background of the track */
}


/* Ensure the react-datepicker-wrapper spans the full width */
.react-datepicker-wrapper {
	width: 100%;
}


/* Make the react-datepicker__input-container fill its parent */
.react-datepicker__input-container {
	width: 100%;
}

/* Adjust input field styling */
.react-datepicker__input-container input[type="text"] {
	width: 100%;
	padding: 8px;
	/* Adjust padding as needed */
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
	/* Example shadow style */
	border: 1px solid #ccc;
	/* Example border color */
	border-radius: 4px;
	/* Example border radius */
	font-size: 14px;
	/* Example font size */
}

/* Optional: Responsive adjustments */
@media (max-width: 640px) {
	.react-datepicker__input-container input[type="text"] {
		font-size: 12px;
	}
}

@keyframes tilt {
	0% {
		transform: rotate(-30deg);
	}

	50% {
		transform: rotate(20deg);
	}

	100% {
		transform: rotate(-30deg);
	}
}

.loader {
	display: inline-block;
	animation: tilt 0.9s ease-in-out infinite;
}



.rce-citem-status {
	top: 15px !important;
	height: 10px !important;
	right: 17px !important;
	width: 10px !important
}



.rce-mbox-right,
.rce-mbox {
	max-width: 80%;
}